import React, { useState, useEffect, useRef } from 'react';
import './css/CountdownTimer.css';

const CountdownTimer = ({ initialSeconds, onTimeout, shouldResetTimer, isTimerActive, isPaused}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const intervalRef = useRef(null);
  const [fontSize, setFontSize] = useState(2); // Initial font size

  useEffect(() => {
     if (shouldResetTimer) {
        // Reset the timer
        clearInterval(intervalRef.current);
        setSeconds(initialSeconds);
      }

      if (isTimerActive & !isPaused) {
        // Start the timer
        intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(intervalRef.current);
            onTimeout();
            setSeconds(initialSeconds);
            return initialSeconds;
          }
          return prevSeconds - 1;
        });
  
        // Briefly increase the font size on each update
        setFontSize(2.5); // You can adjust the scale factor for a more noticeable effect
        setTimeout(() => {
          setFontSize(2); // Reset the font size after a brief period
        }, 100); // Adjust the duration based on your preference
      }, 1000);
    }
      
    return () => clearInterval(intervalRef.current);
  }, [onTimeout, isTimerActive, isPaused]);

  const getTimerColor = () => {
    if (seconds >= 7) {
      return 'green-text';
    } else if (seconds >= 4) {
      return 'yellow-text';
    } else {
      return 'red-text';
    }
  };

  const timerClass = `countdown-timer ${getTimerColor()}`;

  const style = { fontSize: `${fontSize}em` };

  return <div style={style} className={timerClass}>{seconds} seconds</div>;
};

export default CountdownTimer;
