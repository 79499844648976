import React from 'react';
import './css/ErrorPage.css';

const ErrorPage = ({reason }) => {

    return (
      <>
      JONATHAN GREENBERG
    <div className="homepage-container">
      <img
        src="images/homepage_nba.jpg"
        alt="Background"
        className="homepage-image"
      />
    <div className="modal-overlay">
      <div className="popup">
      <p>{reason === 'server' ? 'Sorry, there was an error. Please try again later!' : 'Sorry, please try again later!'}</p>
        <p>Checkout <a href="https://hoopshype.com" target="_blank" rel="noopener noreferrer">hoopshype.com</a> in the meanwhile</p>
      </div>
    </div>
    </div>
    </>
  );
};

export default ErrorPage;
