import './css/Score.css';
import React, { useEffect, useState } from 'react';

const Score = ({ score, strikes }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    // Add event listener to update isMobile on window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <div className="score">
      <p className="score-text">
        Strikes: <span style={{ color: 'red' }}>{strikes}</span>/3 {isMobile && <br />} Score: {score}
      </p>
    </div>
  );
};

export default Score;

