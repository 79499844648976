import React, { useState } from 'react';
import './css/Homepage.css';

const Homepage = ({ onButtonClick }) => {
    const [selectedQuizType, setSelectedQuizType] = useState('salary');


    const handleQuizTypeChange = (event) => {
        setSelectedQuizType(event.target.value);
      };
  
    const handleStartButtonClick = () => {
        // Ensure that a quiz type is selected before starting the game
        if (selectedQuizType) {
          onButtonClick(selectedQuizType);
        } else {
          // Handle case where no quiz type is selected
          console.error('Please select a quiz type before starting the game.');
        }
      };

  return (
    <div className="homepage-container">
      <img
        src="images/homepage_nba.jpg"
        alt="Background"
        className="homepage-image"
      />

      <div className="homepage-content">
        <h1>HoopsHype Quiz Game</h1>

        <label htmlFor="quiz-type-dropdown">Select Quiz Type</label>
        <br />
        <select
          id="quiz-type-dropdown"
          value={selectedQuizType}
          onChange={handleQuizTypeChange}
        >
          <option value="salary">Salary</option>
          {/* <option value="stats">Stats</option> */}
          {/* Add more quiz types as needed */}
        </select>

        <br />
        <br />
        <br />

        <button className="start-button" onClick={handleStartButtonClick}>
          Start Playing!
        </button>
      </div>
    </div>
  );
};


export default Homepage;
