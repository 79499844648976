
export class gciProxyImpl {
  constructor() {
    this._ready = false;
    this._q = [];
    this.gciDataLayerBase = {
      'event-type': 'pageview',
      'page-market-name':'smg',
      'content-market-name': 'hoopshype',
      'page-has-video': 'no',
      'page-has-gallery': false,
      'content-ssts-section': 'sports',
      'content-ssts-subsection': 'sports:hoopshype',
      'content-ssts-topic': 'sports:hoopshype',
      'content-ssts-subtopic': 'sports:hoopshype',
      'content-type': 'quiz',
    };
  };
  init = () => {
    if ( this._ready===true ) return;
    if ( window.gciAnalytics && window.gciAnalytics.isReady ) {
      this._ready = true;
      return;
    }
    var this_ = this;
    document.addEventListener("gciAnalyticsReady", function () {
      this_.process();
      this_._ready = true;
      this_.process();
      return;
    });
  };
  process = () => {
    if ( !window.gciAnalytics || !window.gciAnalytics.isReady || this._q.length==0 ) {
      return;
    }
    while ( this._q.length>0 ) {
      let v = this._q.pop();
      this.view(v, true);
    }
  };
  view = (params, force) => {
    let gciDataLayer = {...this.gciDataLayerBase, ...params};
    gciDataLayer['page-experience-type'] = (window.innerWidth <= 800)? 'mobile':'mobileweb';
    gciDataLayer['page-canonical-url'] = gciDataLayer['page-full-url'];
    gciDataLayer['page-referer'] = document.referrer;
    if ( force===true || this._ready ) {
      if ( process.env.NODE_ENV === 'production') {
        window.gciAnalytics.view(gciDataLayer);
      } else {
        console.log(gciDataLayer);
      }
    } else {
      this._q.push(gciDataLayer);
    }
  };
}
export const gciProxy = new gciProxyImpl();
export default gciProxy;
