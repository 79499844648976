import React, { useState } from 'react';
import './css/Header.css';

function Header() {
  return (
      <div className="header-container">
        <div className="header-wrapper">
          <div className="branding">
            <span className="branding__logos">
              <a href="https://hoopshype.com" title="HoopsHype" className="branding__logo-link">
                <img src="https://hoopshype.com/wp-content/uploads/sites/92/2017/01/logo-hoopshype-header.png" alt="HoopsHype" className="branding__logo" height="30" />
              </a>
            </span>
            <span className="branding__fill" />
            <a className="branding__brand" href="https://www.usatoday.com/sports/" target="_blank" title="USA TODAY Sports">
              <img src="https://hoopshype.com/wp-content/uploads/sites/92/2021/03/USATODAY_Sports_Log.png" alt="USA Today Sports" className="branding__brand-image" height="24" />
            </a>
          </div>
        </div>
      </div>
  )
}

export default Header

