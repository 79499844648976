import React, { useState, useEffect} from 'react';
import {Route, Routes, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './App.css';
import gciProxy from './components/base/gciAnalytics';
import Score from './/components/base/Score';
import AnswerOptionButton from './components/base/AnswerOptionButton';
import CountdownTimer from './components/base/CountdownTimer';
import LostGameModal from './components/pages/LostGameModal';
import Homepage from './components/pages/Homepage';
import CounterUp from './components/base/CounterUp'; // Adjust the path as needed
import ErrorPage from './components/pages/ErrorPage'; // Adjust the path as needed
import Header from './components/pages/Header';

const App = () => {
  const [quizType, setQuizType] = useState('salary');
  const [depth, setDepth] = useState(0);
  const [canClick, setCanClick] = useState(false);
  const [score, setScore] = useState(0);
  const [finalScore, setFinalScore] = useState(0);
  const [question, setQuestion] = useState('');
  const [showTimeoutModal, setShowTimeoutModal] = useState(false); // Separate state for timeout modal
  const [showWrongAnswerModal, setShowWrongAnswerModal] = useState(false); // Separate state for wrong answer modal
  const [shouldResetTimer, setShouldResetTimer] = useState(false);
  const [isTimerActive, setTimerActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [startCounter, setStartCounter] = useState(false);
  const [questionQueue, setQuestionQueue] = useState([]);
  const [errorPage, setErrorPage] = useState(false);
  const [strikes, setStrikes] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);


  let [lastAnswerTime, setLastAnswerTime] = useState(Date.now()); // Unix timestamp in milliseconds
  let [answer, setAnswer] = useState('');
  let [player1MakesMore, setPlayer1MakesMore] = useState(false);
  let [player2MakesMore, setPlayer2MakesMore] = useState(false);

  useEffect(() => {
    if ( process.env.NODE_ENV === 'production') {
      gciProxy.init();
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    // Add event listener to update isMobile on window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  const [imagesLoaded, setImagesLoaded] = useState({
    photo1: false,
    photo2: false,
  });

  const [showHomepage, setShowHomepage] = useState(true);


  const startGame = () => {
    if ( process.env.NODE_ENV === 'production') {
      gciProxy.view({'page-full-url':document.location.href, 'content-name':document.location.pathname, 'content-title':document.title});
    }
    setShowHomepage(false);
    setLastAnswerTime(Date.now());
    setTimerActive(true);
    setCanClick(true);
    };

  const handleHomepageButtonClick = (selectedQuizType) => {
    console.log("I selected a quiz: " + selectedQuizType);
    fetchNextQuestion(selectedQuizType);
    window.history.pushState(null, '', "/" + selectedQuizType + "-quiz")
    setQuizType(selectedQuizType);
    startGame();
  };

  const quizTypeOptions = [
    { value: 'salary', label: 'salary' },
    { value: 'stats', label: 'stats' },
    // Add more quiz types as needed
  ];

  const handleImageLoad = (photo) => {
    // Update the corresponding imageLoaded state
    setImagesLoaded((prev) => ({ ...prev, [photo]: true }));
  
    // Check if both images are loaded
    if (imagesLoaded.photo1 && imagesLoaded.photo2) {
      // Set a timeout to delay the removal of the loading modal
      setTimeout(() => {
        // Remove the loading modal
        setImagesLoaded((prev) => ({ ...prev, loaded: true }));
      }, 1000); // Adjust the timeout duration as needed
    }
  };
    
  {!imagesLoaded.loaded && (
    <div className="loading-overlay">
      <div className="loading-text">Get ready for your first question...</div>
    </div>
  )}

  //todo this is how i add a question url, check if it contains qid='' and then search for that specific one
  useEffect(() => {
    try {
    // add additional quiz types here
    let pathname = document.location.pathname.toLowerCase();
    if ( pathname=='/') {
      if ( process.env.NODE_ENV === 'production') {
        document.location.href = '/salary-quiz'; // default quiz
      }
    } else if ( pathname=='/salary-quiz' || pathname=='/salary-quiz/' ) {
      setQuizType('salary');
      startGame();
      fetchNextQuestion(quizType);
    } else {
      document.location.href = '/';
    }
  }
  catch {
    setErrorPage(true)
  }
  }, [quizType]);

  useEffect(() => {
    console.log('errorPage value:', errorPage);
  }, [errorPage]);
  useEffect(() => {
    if ( Intl.DateTimeFormat().resolvedOptions().timeZone.indexOf("Europe") === 0 ) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://platform-api.sharethis.com/js/sharethis.js#product=sop';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  });
  const loadOneQuestion = async (quizType) => {
    try {
      const quizType = 'salary';
      let url = `/api/next-question?quizType=${quizType}`;
      if ( process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:3001' + url;
      }
      const response = await fetch(url);
      const data = await response.json();
      console.log('heres some question data for load: ' + data.question.photo1);

      // Preload images for the question
      const image1 = new Image();
      image1.src = data.question.photo1;

      const image2 = new Image();
      image2.src = data.question.photo2;
  
      return {
        ...data.question
      };
    } catch (error) {
      console.log('Error in loadOneQuestion:', error.message);
      setErrorPage(true);
    }
  };

  const loadMoreQuestions = async (quizType) => {
    try {
      const preloadedQuestions = [];
  
      for (let i = 0; i < 3; i++) {
        let v = await loadOneQuestion(quizType);
        preloadedQuestions.push(v);
      }
      // Enqueue the preloaded questions
      setQuestionQueue((prev) => [...prev, ...preloadedQuestions]);
    } catch (error) {
      console.log('Error in loadMoreQuestions:', error.message);
      setErrorPage(true);
    }
  };

  const fetchNextQuestion = async (quizType) => {
    try {
    if ( questionQueue.length === 0 ) {  // on startup
      let nextQuestion = await loadOneQuestion(quizType);
      setQuestion(nextQuestion);
    } else {
      const nextQuestion = questionQueue.pop();
      setQuestion(nextQuestion);
    };

    if ( questionQueue.length < 3 ) {
      loadMoreQuestions(quizType);
    }
  }
  catch {
    setErrorPage(true)
    console.log('errorpage value in fetchNextQuestion:' + errorPage);
  }
  };

  async function getAnswerInfo(questionId, quizType) {
    try {
      let url = `/api/question-answer-info?questionId=${questionId}&quizType=${quizType}`;
      if ( process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:3001' + url;
      }
      const response = await fetch(url);
      const data = await response.json();
      setAnswer(data.answer);

      if (data) {
        return data
      } else {
        console.error('Invalid or missing data in the API response');
      }
    } catch (error) {
      console.log('Error fetching answer info:', error);
      setErrorPage(true)
    }
  };

  const handleAnswer = async (id, correctPlayer) => {
    try {
      let elapsedTime = (Date.now()-lastAnswerTime)/1000;
      setLastAnswerTime(Date.now());

      const answerInfo = await getAnswerInfo(id, quizType);
      console.log(answerInfo);
      setStartCounter(true);

      setPlayer1MakesMore(answerInfo.answer.player1amount > answerInfo.answer.player2amount);
      setPlayer2MakesMore(answerInfo.answer.player2amount > answerInfo.answer.player1amount);
      
      setIsPaused(true)
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Check if the answer is correct and handle accordingly
      if (answerInfo.answer.correct === correctPlayer) {
        handleCorrectAnswer(elapsedTime);
      } else {
        handleWrongAnswer();
      }
    } catch (error) {
      console.log('Error handling answer:', error);
      setErrorPage(true);
      
    }
  };

  const handleCorrectAnswer = (elapsedTime) => {
    if (lastAnswerTime) {
      elapsedTime = Math.floor(elapsedTime);
      // console.log(elapsedTime);
    //score update logic
    if (elapsedTime >= 0 && elapsedTime <= 3) {
      setScore(score +  20);
    } else if (elapsedTime >= 4 && elapsedTime <= 7) {
      setScore(score +  15);
    } else if (elapsedTime >= 8 && elapsedTime <= 10) {
      setScore(score +  10);
    }
  }

  continueGameWithNextQuestion(quizType);
  };

  const continueGameWithNextQuestion = (quizType) => {
    fetchNextQuestion(quizType);
    setShouldResetTimer(true);
    setTimerActive(true);
    setIsPaused(false)
    setPlayer1MakesMore(false);
    setPlayer2MakesMore(false);
    setDepth(depth+1);
    if ( process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: "pageview", page: document.location.pathname + '/' + (depth+1), title: document.title + ' - level ' + (depth+1)});
      gciProxy.view({'page-full-url':document.location.href + '/' + (depth+1), 'content-name':document.location.pathname + '/' + (depth+1), 'content-title':document.title + ' - level ' + (depth+1)});
    }
    setCanClick(true);
  }

  const handleCloseTimeoutModal = () => {
    setLastAnswerTime(Date.now());
    setShowTimeoutModal(false);
    setCanClick(true);
    setDepth(0);
    setScore(0);
    setShouldResetTimer(true);
    setTimerActive(true);
    fetchNextQuestion(quizType);
    setIsPaused(false)
    setPlayer1MakesMore(false);
    setPlayer2MakesMore(false);
    if ( process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: document.title});
      gciProxy.view({'page-full-url':document.location.href, 'content-name':document.location.pathname, 'content-title':document.title});
    }
  };
  const handleCloseWrongAnswerModal = () => {
    setLastAnswerTime(Date.now());
    setShowWrongAnswerModal(false);
    setCanClick(true);
    setDepth(0);
    setScore(0);
    setShouldResetTimer(true);
    setTimerActive(true);
    fetchNextQuestion(quizType);
    setIsPaused(false)
    setPlayer1MakesMore(false);
    setPlayer2MakesMore(false);
    if ( process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: document.title});
      gciProxy.view({'page-full-url':document.location.href, 'content-name':document.location.pathname, 'content-title':document.title});
    }
  };

  const handleTimeout = () => {
    setFinalScore(score);
    setCanClick(true);
    setDepth(0);
    setScore(0);
    setShowTimeoutModal(true);
    setTimerActive(false);
  };
  
  const handleWrongAnswer = () => {
    if (strikes + 1 === 3) {
      handleGameOver();
    }
    else{
      continueGameWithNextQuestion(quizType)
      setStrikes((prevStrikes) => prevStrikes + 1);
    }
};

  const handleGameOver = () => {
    setTimerActive(false);
    setFinalScore(score);
    if ( process.env.NODE_ENV === 'production') {
      ReactGA.event(quizType + '_quiz_score', {
        category: 'score',
        label: quizType + '-quiz',
        score: score,
      });
    }
    // Reset strikes when the game is over
    setStrikes(0);

    // Show the wrong answer modal
    setShowWrongAnswerModal(true);
  };  

  const handlePlayerPhotoClick = (player) => {
    if ( canClick===false ) {
      return;
    }
    setCanClick(false);
    handleAnswer(question.id, player);
  };

  return (
    
    <div className="full-screen-container">
      <Header />

      {showHomepage && <Homepage onButtonClick={handleHomepageButtonClick} />}


      {/* <p className="app-title">HoopsHype {isMobile && <br />} NBA Salary Quiz</p> */}

      {question && (
  <h1 className="quiz-question">{question.question}</h1>
)}


      <div className="image-container">

      {question && (
        <div className="image">
        <img
          src={question.photo1}
          alt="Player 1"
          className="image"
          onLoad={() => handleImageLoad('photo1')}
          onClick={() => handlePlayerPhotoClick('player1')}
        />

        <div className="button-container">
        {question && (
          // in the new handler, make call to the server with this option, and return true or false, and then call a handle method
        <AnswerOptionButton onClick={() => handlePlayerPhotoClick('player1')}className="answer-option-button-left">
          {question.player1}
          {player1MakesMore && (
            <>
            <p style={{ color: 'green' }}>$<CounterUp start={startCounter} end={(answer.player1amount - answer.player2amount)}/> more</p>
            </>
            )}
        </AnswerOptionButton>
        )}
      </div>
      </div>
      )}
      

      {question && (
      <div className="image">
          <img
            src={question.photo2}
            alt="Player 2"
            className="image"
            onLoad={() => handleImageLoad('photo2')}
            onClick={() => handlePlayerPhotoClick('player2')}
          />
      <div className="button-container">
        {question && (
          <AnswerOptionButton onClick={() => handlePlayerPhotoClick('player2')} className="answer-option-button-right">
            {question.player2}
            {player2MakesMore && (
            <>
            <p style={{ color: 'green' }}>$<CounterUp start={startCounter} end={(answer.player2amount - answer.player1amount)}/> more</p>
            </>
            )}
          </AnswerOptionButton>
        )}
      </div>
      </div>
      )}
      </div>
      
      <Score score={score} strikes={strikes} />
      <CountdownTimer 
        initialSeconds={10} 
        onTimeout={handleTimeout} 
        shouldResetTimer={shouldResetTimer} 
        isTimerActive={isTimerActive}
        isPaused={isPaused}
        />

      {errorPage && <ErrorPage />}
        

      {/* LostGameModal for wrong answer */}
      {showWrongAnswerModal && (
        <LostGameModal
          onClose={handleCloseWrongAnswerModal}
          finalScore={finalScore}
          reason="wrongAnswer"
        />
      )}

      {/* LostGameModal for timeout */}
      {showTimeoutModal && (
        <LostGameModal
          onClose={handleCloseTimeoutModal}
          finalScore={finalScore}
          reason="timeout"
        />
      )}
    </div>
  );
};

export default App;
