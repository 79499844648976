// LostGameModal.js

import React from 'react';
import './css/LostGameModal.css'; // Import the CSS file

const getScoreTitle = (score) => {
  if (score >= 0 && score <= 40) {
    return 'Rookie Financier';
  } else if (score >= 45 && score <= 100) {
    return 'Seasoned Salary Specialist';
  } else if (score >= 105 && score <= 200) {
    return 'Contract Guru';
  } else if (score >= 205 && score <= 300) {
    return 'Cap Wizard';
  } else if (score >= 305 && score <= 400) {
    return 'Financial MVP';
  } else {
    return 'New Daryl Morey';
  }
};

const LostGameModal = ({ onClose, finalScore, reason }) => {
    const userTitle = getScoreTitle(finalScore);
    if ( finalScore > 0 && window.__sharethis__ ) {
	setTimeout(() => {
	    // https://sharethis.com/support/guides/reinitialize-sharethis-buttons-specific-sharing-parameters/
	    window.__sharethis__.load('inline-share-buttons', {
	      alignment: 'center',
	      id: 'my-inline-buttons',
	      enabled: true,
	      font_size: 14,
	      padding: 8,
	      radius: 8,
	      networks: ['twitter'],
	      labels: 'cta',
	      size: 32,
	      show_total: false,
	      use_native_counts: false,
	      show_mobile_buttons: true,
	      spacing: 0,
	      url: document.location.toString(),
	      title: "I got " + finalScore + " points on HoopsHype's " + window.document.title + '.  I\'m a ' + userTitle,
	      language: 'en',
	      username: 'hoopshype', // custom @username for twitter sharing
        image: 'https://hoopshype.com/wp-content/uploads/sites/92/2023/07/most_paid2_720-1.png',
        description: "My Custom Description"
	    });
	}, 500);
    }
    return (
    <div className="modal-overlay">
      <div className="popup">
      <p>{reason === 'timeout' ? 'Sorry, you ran out of time!' : 'Sorry, you answered incorrectly 3 times!'}</p>
        <b>Final score: {finalScore}</b>
        <p>You're a <b>{userTitle}</b></p>
        <button onClick={onClose}>Challenge Again</button>
	{ finalScore > 0 && window.__sharethis__ && (
	<div>
          
          <p> Show Off Your Rank!</p>
	  <div id="my-inline-buttons"></div>
	</div>
	)}
      </div>
    </div>
  );
};

export default LostGameModal;
