import React from 'react';
import CountUp from 'react-countup';

const CounterUp = ({ start, end }) => {
  return (
    <CountUp start={start ? 0 : null} end={end} duration={1} separator="," />
  );
};

export default CounterUp;
