import React from 'react';
import PropTypes from 'prop-types';
import './css/AnswerOptionButton.css';

const AnswerOptionButton = ({ onClick, children, className }) => {
  const buttonClassName = `answer-option-button ${className || ''}`;

  return (
    <button className={buttonClassName} onClick={onClick}>
      {children}
    </button>
  );
};

AnswerOptionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string, // Allow additional class names to be passed
};

export default AnswerOptionButton;

