import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

const meta = document.createElement('meta');
meta.name = 'viewport';
meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
document.head.appendChild(meta);

const root = ReactDOM.createRoot(document.getElementById('root'));

if ( process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-RP2P26Y4LG');
}

root.render(
    <Router>
      <App />
    </Router>
  ,document.getElementById('root')
);

reportWebVitals();
